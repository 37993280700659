<template>
    <div>
        
        <div class="pageWrap checkoutWrap pt-5 mt-5">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-xl-5">
                        <div class="display-card paymentInfo-wrap text-center">
                            <div class="unsuccess-icon">
                                <i class="icon-sad"></i>
                            </div>
                            <h2>Payment Unsuccessful</h2>
                            <p>
                                We regret to inform you that your payment was not successful. We apologize for any inconvenience this may have caused.

                                If you encountered an issue during the payment process, please try again or contact our support team for assistance.

                                Thank you for your understanding.
                            </p>
                            <router-link :to="{ name: 'CartCheckout'}" class="univ-btn">
                                Try Again
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>
<script>

export default {
    name:'Failure',
    mounted() {
        document.title = 'Payment Fail';
    },
}
</script>